import React from 'react';
import Button from '../NavButton';
import { NavLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import isItemActive from './utils/isItemActive';

const useStyles = makeStyles(theme => ({
  navButton: {
    marginRight: '5px',
  }
}));

function DesktopNavigation (props) {
  const classes = useStyles();

  return (
    <>
      {
        props.items.map(item => (
          <Button
            key={item.url}
            component={NavLink}
            to={item.url}
            className={classes.navButton}
            startIcon={item.label && item.icon}
            disabled={item.disabled || false}
            iconOnly={!item.label}
            isActive={(match, location) => isItemActive(item, location)}
          >
            {item.label || item.icon}
          </Button>
        ))
      }
    </>
  );
}

export default DesktopNavigation;