import { flow, action, makeObservable, observable } from 'mobx';
import Api from '../Api';

class SettingsStore {

  settings = {};
  countries = [];
  timezones = [];

  constructor() {
    makeObservable(this, {
      settings: observable,
      countries: observable,
      timezones: observable,
      fetchSettings: action,
      fetchCountries: action,
      fetchTimezones: action,
    });
  }

  fetchSettings = flow(function* () {
    try {
      const res = yield Api.get('settings');
      this.data = res.data || [];
    } catch(err) {
      console.error(err.message);
    } finally {
      //this.isLoading = false;
      //this.isLoaded = true;
    }
  }).bind(this);

  fetchCountries = flow(function* () {
    try {
      const res = yield Api.get('settings/countries');
      this.countries = res.data || [];
    } catch(err) {
      console.error(err.message);
    } finally {
      //this.isLoading = false;
      //this.isLoaded = true;
    }
  }).bind(this);

  fetchTimezones = flow(function* () {
    try {
      const res = yield Api.get('settings/timezones');
      this.timezones = res.data || [];
    } catch(err) {
      console.error(err.message);
    } finally {
      //this.isLoading = false;
      //this.isLoaded = true;
    }
  }).bind(this);

}

export default SettingsStore;