import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import {
  Board,
  BoardDivider,
  BoardHeading
} from 'components/Board';
import Listing from 'components/Listing';
import {
  AirplaneTakeoff as AirplaneTakeoffIcon,
  AirplaneLanding as AirplaneLandingIcon,
} from 'mdi-material-ui';
import { useStores } from '../../stores';
import { useStyles } from './styles';
import {
  makeAirportTitle,
  makeAirportSubtitle,
  makeDepartureColumns,
  makeArrivalColumns,
  makeDepartureRows,
  makeArrivalsRows,
} from './utils';

const POLLING_INTERVAL = 30000;

function AirportPage(props) {
  const {
    appStore,
    searchStore,
    airportStore,
  } = useStores();

  const classes = useStyles();
  let pollingIntervalId = null;

  const handleSearch = (value) => {
    console.debug('Setting airport from search');
    airportStore.selectAirport(value);
    props.history.push(`/airport/${value}`);
    localStorage.setItem('lastAirport', value);
  };

  useEffect(() => {
    searchStore.setParams({
      label: 'Enter ICAO-code',
      length: 4,
    });
    searchStore.setHandler(handleSearch);
  }, []);

  useEffect(
    () => appStore.toggleLoader(airportStore.isLoading),
    [airportStore.isLoading]
  );

  useEffect(() => {
    (() => {
      if (!airportStore.selected) {
        return;
      }

      if (searchStore.value !== airportStore.selected) {
        searchStore.setValue(airportStore.selected);
      }

      console.debug('Loading data for airport', airportStore.selected);
      airportStore.fetchAirport();
      airportStore.fetchFlights();

      if (!pollingIntervalId) {
        console.debug('Starting polling interval');
        pollingIntervalId = setInterval(
          () => airportStore.fetchFlights(),
          POLLING_INTERVAL
        );
      }
    })();

    return () => {
      if (pollingIntervalId) {
        console.debug('Clearing polling interval');
        clearInterval(pollingIntervalId);
      }
    };
  }, [airportStore.selected]);

  return (
    <>
      <Board>
        <BoardHeading
          title={makeAirportTitle(airportStore.airport)}
          subtitle={makeAirportSubtitle(airportStore.airport)}
          icon={AirplaneTakeoffIcon}
          timestamp={airportStore.updatedAt}
        />

        <Listing
          columns={makeDepartureColumns()}
          rows={makeDepartureRows(airportStore.departures, classes)}
        />

        <BoardDivider />

        <BoardHeading
          title={makeAirportTitle(airportStore.airport)}
          subtitle={makeAirportSubtitle(airportStore.airport)}
          icon={AirplaneLandingIcon}
          timestamp={airportStore.updatedAt}
        />

        <Listing
          columns={makeArrivalColumns()}
          rows={makeArrivalsRows(airportStore.arrivals, classes)}
        />
      </Board>
    </>
  );
}

export default observer(AirportPage);