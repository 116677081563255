import React from 'react';
import { useHeadingStyles } from './styles';

function Heading(props) {
  const {
    title,
    subtitle,
    icon: IconComponent,
    timestamp,
  } = props;

  const classes = useHeadingStyles();

  return (
    <>
      <div className={classes.container}>
        <div className={classes.iconContainer}>
          <IconComponent
            className={classes.iconSvg}
            color='secondary'
          />
        </div>
        <div>
          <h1 className={classes.title}>
            {title}
          </h1>
          <h2 className={classes.subtitle}>
            {subtitle}
          </h2>
        </div>
        {
          timestamp && (
            <div className={classes.date}>
              {timestamp.format('MMMM Do')}<span className={classes.dateTime}>{timestamp.format('HH:mm')}</span>
            </div>
          )
        }
      </div>
    </>
  );
}

export default Heading;