import { matchPath } from 'react-router';
import { PAGE_AIRPORT } from '../../../constants';

const isItemActive = (item, location) => {
  if (location.pathname === '/' && item.page === PAGE_AIRPORT) {
    return true;
  }
  return !!matchPath(location.pathname, {
    path: `/${item.page}`,
    exact: false,
  });
};

export default isItemActive;