import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import greenColor from '@material-ui/core/colors/green';
import redColor from '@material-ui/core/colors/red';
import yellowColor from '@material-ui/core/colors/yellow';
import lightBlueColor from '@material-ui/core/colors/lightBlue';

const CIRCLE_RADIUS = 5;
const INNER_OFFSET = 3;
const BASE_COLOR = '#fff';

const CONFIG = {
  boarding: {
    color: lightBlueColor[300],
    animated: false,
  },
  departing: {
    color: lightBlueColor[300],
    animated: true,
  },
  climbing: {
    color: yellowColor[400],
    animated: true,
  },
  en_route: {
    color: greenColor[400],
    animated: true,
  },
  approach: {
    color: yellowColor[400],
    animated: true,
  },
  landed: {
    color: redColor[400],
    animated: true,
  },
  on_blocks: {
    color: redColor[400],
    animated: false,
  },
  unknown: {
    color: '#fff',
    animated: false,
  }
};

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexWrap: 'nowrap',

    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
    },
  },
  svg: {
    margin: '0 10px',

    [theme.breakpoints.down('xs')]: {
      margin: '0',
    }
  },
  label: {
    flexGrow: 1,
    fontSize: '11px',
    fontWeight: 'bold',

    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  background: {
    fill: 'none',
  },
  animationBase: {
    animationName: '$blinking',
    animationDuration: '2s',
    animationIterationCount: 'infinite',
    animationTimingFunction: 'steps(1)',
  },
  animationReverse: {
    animationDirection: 'reverse',
  },
  leftCircle: {
    animationDirection: 'reverse',
  },
  rightBlinkingCircle: {
    animationDirection: 'reverse',
  },
  '@keyframes blinking': {
    '0%': { opacity: 0 },
    '50%': { opacity: 1 },
    '100%': { opacity: 0 },
  },
}));

function FlightState(props) {
  const classes = useStyles({
    secondaryColor: 'white',
    primaryColor: 'green',
    type: 'green',
  });

  const color = CONFIG[props.id]?.color || '';
  const animated = CONFIG[props.id]?.animated ?? true;

  const width = 3 * INNER_OFFSET + 4 * CIRCLE_RADIUS;
  const height = 2 * INNER_OFFSET + 2 * CIRCLE_RADIUS;
  const cy = INNER_OFFSET + CIRCLE_RADIUS;
  const cx1 = INNER_OFFSET + CIRCLE_RADIUS;
  const cx2 = 2 * INNER_OFFSET + 3 * CIRCLE_RADIUS;

  return (
    <div className={classes.container}>
      <span className={classes.label} style={{ color }}>
        {props.title}
      </span>
      <svg className={classes.svg} width={width} height={height} xmlns='http://www.w3.org/2000/svg'>
        <g>
          <title>background</title>
          <rect className={classes.background} width={width} height={height} y='0' x='0' />
        </g>
        <g>
          <title>circles</title>
          <ellipse
            className={animated ? classNames(classes.animationBase, classes.animationReverse) : undefined}
            fill={animated ? BASE_COLOR : color}
            ry={CIRCLE_RADIUS}
            rx={CIRCLE_RADIUS}
            cy={cy}
            cx={cx1}
          />
          <ellipse
            className={animated ? classes.animationBase : undefined}
            fill={animated ? BASE_COLOR : color}
            ry={CIRCLE_RADIUS}
            rx={CIRCLE_RADIUS}
            cy={cy}
            cx={cx2}
          />
          {
            animated && (
              <>
                <ellipse
                  className={classes.animationBase}
                  fill={color}
                  ry={CIRCLE_RADIUS}
                  rx={CIRCLE_RADIUS}
                  cy={cy}
                  cx={cx1}
                />
                <ellipse
                  className={classNames(classes.animationBase, classes.animationReverse)}
                  fill={color}
                  ry={CIRCLE_RADIUS}
                  rx={CIRCLE_RADIUS}
                  cy={cy}
                  cx={cx2}
                />
              </>
            )
          }
        </g>
      </svg>
    </div>
  );
}

export default FlightState;