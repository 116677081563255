import React from 'react';
import { observer } from 'mobx-react';
import {
  Airport as AirportIcon,
  Airplane as AirplaneIcon,
  Antenna as AntennaIcon,
  Home as HomeIcon,
  Cog as CogIcon,
} from 'mdi-material-ui';
import { useTranslation } from 'react-i18next';
import { useStores } from 'stores';
import { Hidden } from '@material-ui/core';
import MobileNavigation from './MobileNavigation';
import DesktopNavigation from './DesktopNavigation';


const getNavItems = (t) => {
  return [
    {
      //link: getLink('airline', searchStore.airline),
      //route: '/airline',
      page: 'airline',
      label: t('common:airline'),
      icon: <AirplaneIcon />,
      disabled: false,
    },
    {
      //link: getLink('airport', searchStore.airport),
      //route: '/airport',
      page: 'airport',
      label: t('common:airport'),
      icon: <AirportIcon />,
      disabled: false,
    },
    {
      page: 'country',
      label: 'Country',
      icon: <HomeIcon />,
      disabled: true,
    },
    {
      page: 'atc',
      label: 'ATC',
      icon: <AntennaIcon />,
      disabled: true,
    },
    {
      page: 'settings',
      label: 'Settings',
      icon: <CogIcon fontSize='small' />,
      disabled: true,
    },
  ];
};

function Navigation(props) {
  const [t] = useTranslation();

  const {
    airlineStore,
    airportStore,
  } = useStores();

  const getFullUrl = (page) => {
    let uri = null;

    if (page === 'airline') {
      uri = airlineStore.selected;
    } else if (page === 'airport') {
      uri = airportStore.selected;
    }

    return `/${page}${uri ? `/${uri}` : ''}`;
  };

  const items = getNavItems(t).map(item => ({
    ...item,
    url: getFullUrl(item.page),
  }));

  return (
    <nav>
      <Hidden smDown>
        <DesktopNavigation
          items={items}
        />
      </Hidden>
      <Hidden mdUp>
        <MobileNavigation
          items={items}
        />
      </Hidden>
    </nav>
  );
}

export default observer(Navigation);