import React from 'react';
import ReactCountryFlag from 'react-country-flag';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

const useStyles = makeStyles(theme => ({
  container: {

  },
  flag: {
    marginBottom: '2px',
  },
  flagLeft: {
    marginRight: '8px',
  },
  flagRight: {
    marginLeft: '8px',
  }
}));


function AirportName(props) {
  const {
    data,
    withCodeOnly = false,
    withFlag = false,
    flagPlacement,
    className,
  } = props;

  const classes = useStyles();

  return (
    <span className={classNames(classes.container, className)}>
      {
        withFlag && flagPlacement !== 'right' && data?.country_code && (
          <ReactCountryFlag
            className={classNames(classes.flag, classes.flagLeft)}
            countryCode={data.country_code}
            svg
          />
        )
      }
      {withCodeOnly && data.icao}
      {!withCodeOnly && (data.city || data.icao)}
      {
        withFlag && flagPlacement === 'right' && data?.country_code && (
          <ReactCountryFlag
            className={classNames(classes.flag, classes.flagRight)}
            countryCode={data.country_code}
            svg
          />
        )
      }
    </span>
  );
}

export default AirportName;