import React from 'react';
import Table from './Table';

function Listing(props) {
  const {
    columns,
    rows,
  } = props;

  return (
    <>
      <Table
        columns={columns}
        rows={rows}
      />
    </>
  );
}

export default Listing;