import React from 'react';
import { useStyles } from './styles';

function Board(props) {
  const {
    withDivider,
  } = props;

  const classes = useStyles();

  return (
    <div className={classes.container}>
      {props.children}
      {
        withDivider && (
          <div className={classes.divider} />
        )
      }
    </div>
  );
}

export default Board;