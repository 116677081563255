import React from 'react';
import { useStyles } from './styles';

function Body(props) {
  const classes = useStyles();

  return (
    <div className={classes.body}>
      {props.children}
    </div>
  );
}

export default Body;