import { flow, action, makeObservable, observable } from 'mobx';
import Api from '../Api';
import moment from 'moment';
import BaseStore from './BaseStore';

class AirlineStore extends BaseStore {

  updatedAt = null;

  selected = null;
  airline = {};

  flights = [];

  constructor() {
    super();

    makeObservable(this, {
      updatedAt: observable,
      selected: observable,
      airline: observable,
      flights: observable,
      selectAirline: action,
      fetchAirline: action,
      fetchFlights: action,
    });
  }

  selectAirline = (code) => {
    this.selected = code;
  }

  fetchAirline = flow(function* () {
    this.isLoading = true;

    try {
      const res = yield Api.get('info/airline/' + this.selected);

      this.airline = res?.data || {};
    } catch(err) {
      console.error(err.message);
    } finally {
      this.isLoading = false;
    }
  }).bind(this);

  fetchFlights = flow(function* () {
    this.isLoading = true;

    try {
      const res = yield Api.get('flights/airline', { code: this.selected });

      this.updatedAt = moment.unix(res?.data?.updated_at || null);
      this.flights = res?.data?.flights || [];
    } catch(err) {
      console.error(err.message);
    } finally {
      this.isLoading = false;
    }
  }).bind(this);

}

export default AirlineStore;