import { DASH } from '../../constants';
import Tooltip from '../Tooltip';
import React from 'react';

export const makeCallsign = (item) => (
  <a target='_blank' href={`https://webeye.ivao.aero/?pilotId=${item.id}`} rel='noreferrer'>
    <strong>{item.callsign}</strong>
  </a>
);

export const makeAircraft = (aircraft) => {
  if (!aircraft?.icao) {
    return DASH;
  }

  if (!aircraft.name) {
    return aircraft.icao;
  }

  return (
    <Tooltip title={aircraft.name} placement='top' arrow>
      <span>{aircraft.icao}</span>
    </Tooltip>
  );
};