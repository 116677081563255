import { flow, action, makeObservable, observable } from 'mobx';
import Api from '../Api';
import moment from 'moment';
import BaseStore from './BaseStore';

class FlightStore extends BaseStore {

  updatedAt = null;

  airline = {};
  airport = {};

  airlineFlights = [];
  airportDepartures = [];
  airportArrivals = [];

  pollingInterval = 30000;
  pollingIntervalId = null;

  constructor() {
    super();

    makeObservable(this, {
      updatedAt: observable,
      airline: observable,
      airport: observable,
      airlineFlights: observable,
      airportDepartures: observable,
      airportArrivals: observable,
      fetchByAirline: action,
      fetchByAirport: action,
    });
  }

  fetchAirline = flow(function* (code) {
    this.isLoading = true;

    try {
      const res = yield Api.get('info/airline/' + code);

      this.airline = res?.data || {};
    } catch(err) {
      console.error(err.message);
    } finally {
      this.isLoading = false;
    }
  }).bind(this);

  fetchAirport = flow(function* (code) {
    this.isLoading = true;

    try {
      const res = yield Api.get('info/airport/' + code);

      this.airport = res?.data || {};
    } catch(err) {
      console.error(err.message);
    } finally {
      this.isLoading = false;
    }
  }).bind(this);

  fetchByAirport = flow(function* (code) {
    this.isLoading = true;

    try {
      const res = yield Api.get('flights/airport', { code });

      this.updatedAt = moment.unix(res?.data?.updated_at || null);
      this.airportDepartures = res?.data?.departures || [];
      this.airportArrivals = res?.data?.arrivals || [];
    } catch(err) {
      console.error(err.message);
    } finally {
      this.isLoading = false;
    }
  }).bind(this);

  fetchByAirline = flow(function* (code) {
    this.isLoading = true;

    try {
      const res = yield Api.get('flights/airline', { code });

      this.updatedAt = moment.unix(res?.data?.updated_at || null);
      this.airlineFlights = res?.data?.flights || [];
    } catch(err) {
      console.error(err.message);
    } finally {
      this.isLoading = false;
    }
  }).bind(this);

}

export default FlightStore;