import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  container: {
    opacity: 0.7,
  }
}));

function SecondaryItem(props) {
  const classes = useStyles();

  return (
    <span className={classes.container}>
      {props.children}
    </span>
  );
}

export default SecondaryItem;