import {
  LS_KEY_LAST_AIRPORT,
  LS_KEY_LAST_AIRLINE,
} from '../constants';

const getLastSearchCodes = () => ({
  airport: localStorage.getItem(LS_KEY_LAST_AIRPORT),
  airline: localStorage.getItem(LS_KEY_LAST_AIRLINE),
});

export default getLastSearchCodes;