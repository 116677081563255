import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  container: {
    backgroundColor: theme.palette.secondary.main,
  },
  body: {
    padding: '20px',
  },
  divider: {
    backgroundColor: theme.palette.brand.grey,
    height: '20px',
    width: '100%',

    [theme.breakpoints.down('xs')]: {
      height: '10px',
    },
  },
}));

export const useHeadingStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    padding: '10px 20px',

    [theme.breakpoints.down('xs')]: {
      padding: '10px',
    },
  },
  iconContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '55px',
    height: '55px',
    marginRight: '20px',
    backgroundColor: theme.palette.primary.main,
    flexShrink: 0,

    [theme.breakpoints.down('sm')]: {
      width: '45px',
      height: '45px',
    },
  },
  iconSvg: {
    width: '50px',
    height: '50px',

    [theme.breakpoints.down('sm')]: {
      width: '40px',
      height: '40px',
    },
  },
  title: {
    fontSize: '22px',
    lineHeight: '24px',
    marginBottom: '4px',
    marginTop: '0px',
    textShadow: '2px 2px 2px rgba(0, 0, 0, .5)',

    [theme.breakpoints.down('sm')]: {
      fontSize: '20px',
      marginBottom: '2px',
    },

    [theme.breakpoints.down('xs')]: {
      fontSize: '16px',
      lineHeight: '16px',
      marginBottom: '6px',
    },
  },
  subtitle: {
    fontSize: '18px',
    lineHeight: '20px',
    marginTop: '0px',
    marginBottom: 0,
    textShadow: '2px 2px 2px rgba(0, 0, 0, .5)',

    [theme.breakpoints.down('sm')]: {
      fontSize: '16px',
    },

    [theme.breakpoints.down('xs')]: {
      fontSize: '12px',
      lineHeight: '12px',
    },
  },
  date: {
    justifySelf: 'flex-end',
    flexGrow: 1,
    textAlign: 'right',
    lineHeight: '20px',
    fontSize: '16px',

    [theme.breakpoints.down('sm')]: {
      whiteSpace: 'nowrap',
    },

    [theme.breakpoints.down('xs')]: {
      fontSize: '14px',
    },
  },
  dateTime: {
    display: 'inline-block',
    fontWeight: 'bold',
    borderLeft: `1px solid ${theme.palette.primary.main}`,
    color: theme.palette.primary.main,
    paddingLeft: '11px',
    marginLeft: '12px',

    [theme.breakpoints.down('sm')]: {
      display: 'block',
      borderLeft: '0',
    },
  },
}));