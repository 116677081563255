import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
//import Backend from 'i18next-xhr-backend';
//import LanguageDetector from 'i18next-browser-languagedetector';

import commonEn from './locales/en-US/common.json';
import commonRu from './locales/ru-RU/common.json';

const resources = {
  'en-US': {
    common: commonEn,
  },
  'ru-RU': {
    common: commonRu,
  }
};

i18n
  //.use(Backend)
  //.use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    defaultLanguage: 'ru-RU',
    //otherLanguages: ['en-US'],
    ns: ['common'],
    load: 'current',
    fallbackLng: 'en-US',
    defaultNS: 'common',
    interpolation: {
      escapeValue: false
    },
    detection: {
      lookupCookie: 'locale',
      cookieDomain: process.env.REACT_APP_ROOT_DOMAIN
    },
    react: {
      wait: true,
      useSuspense: false
    }
  });

export default i18n;
