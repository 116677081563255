import React, { useState, useEffect, useRef } from 'react';
import Button from '../NavButton';
import { Menu, MenuItem, ListItemIcon } from '@material-ui/core';
import { NavLink, useLocation } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import isItemActive from './utils/isItemActive';
import {
  Menu as MenuIcon,
} from 'mdi-material-ui';

const useStyles = makeStyles(theme => ({
  listItemIcon: {
    paddingRight: '16px',
    minWidth: 'auto',
  }
}));

function MobileNavigation(props) {
  const { items: navItems } = props;
  const classes = useStyles();
  const location = useLocation();

  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);

  const menuRef = useRef();

  useEffect(() => {
    setSelectedItem(
      navItems.find(item => isItemActive(item, location))
    );
  }, [navItems, location]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      {
        selectedItem && (
          <Button aria-controls={menuRef} aria-haspopup='true' onClick={handleClick} startIcon={<MenuIcon />}>
            {selectedItem.label}
          </Button>
        )
      }
      <Menu
        id={menuRef}
        as={'div'}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {
          navItems.map(item => (
            <MenuItem
              key={item.url}
              component={NavLink}
              to={item.url}
              exact={false}
              onClick={handleClose}
              disabled={item.disabled || false}
            >
              <ListItemIcon classes={{ root: classes.listItemIcon }}>
                {item.icon}
              </ListItemIcon>
              {item.label}
            </MenuItem>
          ))
        }
      </Menu>
    </>
  );
}

export default MobileNavigation;