import React from 'react';
import { observer } from 'mobx-react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { useStores } from './stores';
import Header from './components/Header';
import {
  Route,
  Router,
} from 'react-router-dom';
import { createBrowserHistory } from 'history';
import AirportPage from './pages/AirportPage';
import AirlinePage from './pages/AirlinePage';
import SettingsPage from './pages/SettingsPage';
import {
  PAGE_AIRLINE,
  PAGE_AIRPORT,
  DEFAULT_AIRLINES,
  DEFAULT_AIRPORTS,
} from './constants';
import random from 'lodash/random';
import getCodeFromUrl from './utils/getCodeFromUrl';
import getLastSearchCodes from './utils/getLastSearchCodes';

const useStyles = makeStyles(theme => ({
  '@global': {
    body: {
      [theme.breakpoints.down('xs')]: {
        backgroundColor: theme.palette.secondary.main
      }
    }
  },
  section: {
    ...theme.mixins.gutters(),
  },
  container: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    //padding: 0,
    //marginTop: 0,
    //marginBottom: 0,

    [theme.breakpoints.down('xs')]: {
      padding: 0,
      marginTop: 0,
      marginBottom: 0,
    }
  },
  frame: {
    backgroundColor: theme.palette.brand.grey,
    padding: '0 20px 20px',
    //padding: 0,

    [theme.breakpoints.down('xs')]: {
      padding: 0,
    }
  }
}));

export const history = createBrowserHistory({
  basename: process.env.PUBLIC_URL
});

function Timetable(props) {
  const classes = useStyles();
  const {
    airportStore,
    airlineStore,
    searchStore,
  } = useStores();

  const { airport: lastAirport, airline: lastAirline } = getLastSearchCodes();
  const { type: pageType, code: pageCode } = getCodeFromUrl();

  (() => {
    let code = DEFAULT_AIRPORTS[random(0, (DEFAULT_AIRPORTS.length - 1))];

    console.log(pageType, pageCode);
    if (pageType === PAGE_AIRPORT && pageCode) {
      code = pageCode;
    } else {
      if (lastAirport) {
        code = lastAirport;
      }
    }

    airportStore.selectAirport(code);

    if (pageType === PAGE_AIRPORT) {
      searchStore.setValue(code);
    }
  })();

  (() => {
    let code = DEFAULT_AIRLINES[random(0, (DEFAULT_AIRLINES.length - 1))];

    if (pageType === PAGE_AIRLINE && pageCode) {
      code = pageCode;
    } else {
      if (lastAirline) {
        code = lastAirline;
      }
    }

    airlineStore.selectAirline(code);

    if (pageType === PAGE_AIRLINE) {
      searchStore.setValue(code);
    }
  })();

  return (
    <Container className={classes.container}>
      <div className={classes.frame}>
        <Router history={history}>
          <Header />

          <Route exact path='/' component={AirportPage} />
          <Route exact path={['/airport', '/airport/:code']} component={AirportPage} />
          <Route exact path={['/airline', '/airline/:code']} component={AirlinePage} />
          <Route exact path='/settings' component={SettingsPage} />
        </Router>
      </div>
    </Container>
  );
}

export default observer(Timetable);