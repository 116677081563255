import { action, makeObservable, observable } from 'mobx';

class AppStore {

  params = {
    searchEnabled: true,
    userEnabled: false,
  }

  loading = false;

  constructor() {
    makeObservable(this, {
      params: observable,
      loading: observable,
      setParams: action,
      toggleLoader: action,
    });
  }

  setParams = (params) => {
    this.params = Object.assign(this.params, params);

    return () => this.setParams({
      searchEnabled: true,
      userEnabled: false,
    });
  }

  toggleLoader = (value) => {
    this.loading = value;
    return () => this.toggleLoader(false);
  }
}

export default AppStore;
