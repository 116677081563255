import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Tooltip as DefaultTooltip } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  arrow: {
    color: theme.palette.brand.grey,
  },
  tooltip: {
    backgroundColor: theme.palette.brand.grey,
    color: '#fff',
    fontSize: '11px',
    fontWeight: 'bold',
    padding: '6px 16px',
  },
}));

function Tooltip(props) {
  const classes = useStyles();

  return (
    <DefaultTooltip classes={classes} {...props}>
      {props.children}
    </DefaultTooltip>
  );
}

export default Tooltip;