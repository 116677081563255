import React from 'react';
import moment from 'moment';
import { useStores } from 'stores';
import Listing from 'components/Listing';
import { makeAircraft, makeCallsign } from 'components/Listing/utils';
import AirlineLogo from 'components/AirlineLogo';
import SecondaryItem from 'components/Listing/SecondaryItem';
import FlightState from 'components/FlightState';
import FlightDirection from 'components/FlightDirection';

const makeColumns = () => ({
  callsign: {
    name: 'Flight',
    width: {
      default: '90px',
      xs: '75px',
    },
    order: 1,
  },
  aircraft: {
    name: 'Aircraft',
    width: '80px',
    order: 2,
    hidden: 'xs',
  },
  flight: {
    name: 'Direction',
    order: 3,
  },
  departureTime: {
    name: 'Dep. Time',
    width: {
      default: '65px',
      xs: '50px',
    },
    order: 5,
  },
  enRouteTime: {
    name: 'Time Enroute',
    width: '65px',
    order: 6,
    hidden: 'sm',
  },
  arrivalTime: {
    name: 'Arrival Time',
    width: {
      default: '65px',
      xs: '50px',
    },
    order: 7,
  },
  status: {
    name: 'Status',
    width: {
      default: '170px',
      sm: '80px',
      xs: '45px',
    },
    order: 8,
  },
});

const makeRows = (data) => data.map((item) => ({
  callsign: makeCallsign(item),
  aircraft: makeAircraft(item.aircraft),
  airline: <AirlineLogo airline={item.airline} callsign={item.callsign} />,
  enRouteTime: (
    <SecondaryItem>
      { moment.utc(item.en_route_time * 1000).format('HH:mm') }
    </SecondaryItem>
  ),
  status: <FlightState id={item.state.id} title={item.state.title} />,
  departureTime: moment.unix(item.departure_at).format('HH:mm'),
  arrivalTime: moment.unix(item.arrival_at).format('HH:mm'),
  flight: <FlightDirection departure={item.departure} destination={item.destination} />
}));

function FlightListing(props) {
  const {
    airlineStore,
  } = useStores();

  return (
    <Listing
      columns={makeColumns()}
      rows={makeRows(airlineStore.flights)}
    />
  );
}

export default FlightListing;