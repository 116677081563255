import React from 'react';
import AirportName from '../AirportName';
import { DASH } from '../../constants';
import { makeStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

const useStyles = makeStyles(theme => ({
  flightDivider: {
    margin: '0 8px',
  },
}));

function FlightDirection(props) {
  const classes = useStyles();

  const isExtraSmall = useMediaQuery(
    theme => theme.breakpoints.down('xs')
  );

  return (
    <>
      <AirportName
        data={props.departure}
        withCodeOnly={isExtraSmall}
        withFlag
        flagPlacement='left'
      />

      <span className={classes.flightDivider}>
        {DASH}
      </span>

      <AirportName
        data={props.destination}
        withCodeOnly={isExtraSmall}
        withFlag
        flagPlacement='right'
      />
    </>
  );
}

export default FlightDirection;