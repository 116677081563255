import React from 'react';
import moment from 'moment';
import FlightState from '../../components/FlightState';
import AirlineLogo from '../../components/AirlineLogo';
import {
  makeCallsign,
  makeAircraft,
} from '../../components/Listing/utils';
import SecondaryItem from '../../components/Listing/SecondaryItem';
import AirportName from '../../components/AirportName';

export const makeAirportTitle = (airport) => {
  return airport.full_name || airport.name || airport.city || '';
};

export const makeAirportSubtitle = (airport) => {
  return `${airport.icao}${airport.iata && ` / ${airport.iata}`}`;
};

const makeCommonColumns = () => ({
  callsign: {
    name: 'Flight Number',
    width: '90px',
    order: 1,
  },
  aircraft: {
    name: 'Aircraft',
    width: '80px',
    order: 2,
    hidden: 'xs',
  },
  airline: {
    name: 'Airline',
    width: '120px',
    order: 3,
    hidden: 'sm',
  },
  departureTime: {
    name: 'Dep. Time',
    width: '65px',
    order: 5,
  },
  enRouteTime: {
    name: 'Time Enroute',
    width: '65px',
    order: 6,
    hidden: 'sm',
  },
  arrivalTime: {
    name: 'Arrival Time',
    width: '65px',
    order: 7,
  },
  status: {
    name: 'Status',
    width: {
      default: '170px',
      sm: '80px',
    },
    order: 8,
  },
});

export const makeDepartureColumns = () => {
  const columns = makeCommonColumns();

  return Object.assign(columns, {
    destination: {
      name: 'Destination',
      order: 4,
    },
    arrivalTime: {
      ...columns.arrivalTime,
      hidden: 'sm',
    },
  });
};

export const makeArrivalColumns = () => {
  const columns = makeCommonColumns();

  return Object.assign(columns, {
    departure: {
      name: 'Departure',
      order: 4,
    },
    departureTime: {
      ...columns.departureTime,
      hidden: 'sm',
    },
  });
};

const makeCommonRow = (item, classes) => ({
  callsign: makeCallsign(item),
  aircraft: makeAircraft(item.aircraft),
  airline: <AirlineLogo airline={item.airline} callsign={item.callsign} />,
  enRouteTime: (
    <SecondaryItem>
      { moment.utc(item.en_route_time * 1000).format('HH:mm') }
    </SecondaryItem>
  ),
  status: <FlightState id={item.state.id} title={item.state.title} />
});

export const makeDepartureRows = (data, classes) => data.map((item) => ({
  ...makeCommonRow(item, classes),
  departureTime: (
    <strong>
      { moment.unix(item.departure_at).format('HH:mm') }
    </strong>
  ),
  arrivalTime: (
    <SecondaryItem>
      { moment.unix(item.arrival_at).format('HH:mm') }
    </SecondaryItem>
  ),
  destination: <AirportName data={item.destination} withFlag />,
}));

export const makeArrivalsRows = (data, classes) => data.map((item) => ({
  ...makeCommonRow(item, classes),
  departureTime: (
    <SecondaryItem>
      { moment.unix(item.departure_at).format('HH:mm') }
    </SecondaryItem>
  ),
  arrivalTime: (
    <strong>
      { moment.unix(item.arrival_at).format('HH:mm') }
    </strong>
  ),
  departure: <AirportName data={item.departure} withFlag />,
}));