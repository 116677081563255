import { makeObservable, observable } from 'mobx';

class BaseStore {

  isLoading = false;
  isLoaded = false;

  constructor() {
    makeObservable(this, {
      isLoading: observable,
      isLoaded: observable,
    });
  }
}

export default BaseStore;