import React from 'react';
import { Provider } from 'mobx-react';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { CssBaseline } from '@material-ui/core';
import stores from './stores';
import Timetable from './Timetable';

const theme = createMuiTheme({
  typography: {
    useNextVariants: true,
    fontSize: 13,
  },
  palette: {
    type: 'dark',
    primary: {
      light: '#fffe51',
      main: '#fecb02',
      dark: '#c69b00',
      contrastText: '#000',
    },
    secondary: {
      light: '#4b6d94',
      main: '#1a4266',
      dark: '#001c3b',
      contrastText: '#fff',
    },
    background: {
      paper: '#212121',
    },
    /*primary: {
      light: '#7986cb',
      main: blue[500],
      dark: blue[700],
      contrastText: '#fff'
    },
    background: {
      paper: '#424242',
      default: '#303030',
      secondary: '#7b7b7b',
      tableHeader: '#3a3a3a'
    },*/
    brand: {
      blue: '#1a4266',
      yellow: '#fecb02',
      grey: '#212121'
    }
  },
  shape: {
    borderRadius: 0,
  }
});

function App() {
  return (
    <MuiThemeProvider theme={theme}>
      <Provider {...stores}>
        <CssBaseline />
        <Timetable />
      </Provider>
    </MuiThemeProvider>
  );
}

export default App;
