import React, { useEffect } from 'react';
import { useStores } from 'stores';
import { observer } from 'mobx-react';
import {
  Cog as CogIcon,
} from 'mdi-material-ui';
import {
  Board,
  BoardBody,
  BoardHeading
} from 'components/Board';
import SettingsForm from './SettingsForm';

function SettingsPage(props) {
  const {
    appStore,
    settingsStore: {
      fetchSettings,
      fetchCountries,
      fetchTimezones,
    },
  } = useStores();

  useEffect(() => appStore.setParams({
    searchEnabled: false,
    userEnabled: true,
  }), [appStore]);

  useEffect(() => {
    fetchCountries();
    fetchTimezones();
    fetchSettings();
  }, [fetchSettings, fetchCountries, fetchTimezones]);

  return (
    <>
      <Board withPadding>
        <BoardHeading
          title='Settings'
          subtitle='Customize your timetable'
          icon={CogIcon}
        />

        <BoardBody>
          <SettingsForm />
        </BoardBody>
      </Board>
    </>
  );
}

export default observer(SettingsPage);