import React from 'react';
import { useStores } from 'stores';
import { Field, Form, Formik } from 'formik';
import Grid from '@material-ui/core/Grid';
import { Button, FormControlLabel, MenuItem, Radio, Typography } from '@material-ui/core';
import { RadioGroup, TextField } from 'formik-material-ui';
import { makeStyles } from '@material-ui/core/styles';
import * as Yup from 'yup';
import { observer } from 'mobx-react';

const useStyles = makeStyles(theme => ({
  form: {
    maxWidth: '50%',
  }
}));

const getValidationSchema = () => Yup.object().shape({
  time: Yup.string().required('required'),
  timezone: Yup.string().required('required'),
  favorite_airline: Yup.string().required('required'),
  favorite_airport: '',
  favorite_country: '',
  favorite_atc_region: '',
});

function SettingsForm(props) {
  const {
    settingsStore: {
      countries,
    },
  } = useStores();

  const classes = useStyles();

  return (
    <>
      <Formik
        validationSchema={getValidationSchema()}
        initialValues={{
          time: 'utc',
          timezone: '',
          favorite_airline: '',
          favorite_airport: '',
          favorite_country: '',
          favorite_atc_region: '',
        }}
        //onSubmit={handleSubmit}
      >
        {({ values, handleSubmit, isSubmitting, isValid }) => (
          <Form onSubmit={handleSubmit} className={classes.form}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography variant='h5' component='h3' gutterBottom>Time settings</Typography>
                <Field component={RadioGroup} name='time' row>
                  <FormControlLabel
                    value='utc'
                    control={<Radio disabled={isSubmitting} color='primary' />}
                    label='UTC time'
                    disabled={isSubmitting}
                  />
                  <FormControlLabel
                    value='local'
                    control={<Radio disabled={isSubmitting} color='primary' />}
                    label='Local time'
                    disabled={isSubmitting}
                  />
                  {/*<FormControlLabel
                    value="timezone"
                    control={<Radio disabled={isSubmitting} color='primary' />}
                    label="Preferred timezone"
                    disabled={isSubmitting}
                  />*/}
                </Field>
                {/*<Field
                  label='Timezone'
                  name='timezone'
                  variant='outlined'
                  component={TextField}
                  disabled={values.time !== 'timezone'}
                  select
                  fullWidth
                >
                  {
                    timezones.map(timezone => (
                      <MenuItem key={timezone.id} value={timezone.id}>{timezone.name}</MenuItem>
                    ))
                  }
                </Field>*/}
              </Grid>
              <Grid item xs={12}>
                <Typography variant='h5' component='h3' gutterBottom>Favorites</Typography>
                <Grid container spacing={3}>
                  <Grid item xs={6}>
                    <Field
                      label='Favorite airline'
                      name='favorite_airline'
                      variant='outlined'
                      component={TextField}
                      helperText={'ICAO-code of the favorite airline'}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      label='Favorite airport'
                      name='favorite_airport'
                      variant='outlined'
                      component={TextField}
                      helperText={'ICAO-code of the favorite airport'}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      label='Favorite country'
                      name='favorite_country'
                      variant='outlined'
                      component={TextField}
                      helperText={'Choose favorite country from the list'}
                      fullWidth
                      select
                    >
                      {
                        countries.map(country => (
                          <MenuItem key={country.id} value={country.id}>{country.name}</MenuItem>
                        ))
                      }
                    </Field>
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      label='Favorite ATC Region'
                      name='favorite_atc_region'
                      variant='outlined'
                      component={TextField}
                      helperText={'ICAO-code of the favorite ATC region'}
                      fullWidth
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Button
                  type='submit'
                  variant='contained'
                  color='primary'
                >
                  Save changes
                </Button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  );
}

export default observer(SettingsForm);