import React from 'react';
import { observer } from 'mobx-react';
import { makeStyles } from '@material-ui/core/styles';
import { Account as AccountIcon } from 'mdi-material-ui';
import { Logout as LogoutIcon } from 'mdi-material-ui';
import Button from '../NavButton';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexGrow: 0,
    alignItems: 'center',
  },
}));

function User(props) {
  const classes = useStyles();

  //const [searchValue, setSearchValue] = useState('');
  const isLoggedIn = false;

  /*const handleSubmit = (event) => {
    onSubmit(searchValue);
    event.preventDefault();
  }*/
  const handleLogin = () => {
    alert('login clicked!');
  };

  const handleLogout = () => {
    alert('logout clicked!');
  };

  if (isLoggedIn) {
    return (
      <div>
        <Button
          onClick={handleLogout}
          className={classes.navButton}
          startIcon={<LogoutIcon />}
        >
          Logout
        </Button>
      </div>
    );
  }

  return (
    <div className={classes.container}>
      <Button
        onClick={handleLogin}
        className={classes.navButton}
        startIcon={<AccountIcon />}
      >
        Login
      </Button>

    </div>
  );
}

export default observer(User);