import { makeStyles } from '@material-ui/core';

export const useTableStyles = makeStyles(theme => ({
  table: {
    width: '100%',
    borderCollapse: 'collapse',
  },
  th: {
    color: '#84a0b8',
    fontSize: '12px',
    fontWeight: 'bold',
    height: '40px',
  },
  tr: {
    '& td': {
      height: '30px',
      fontSize: '13px',
      textAlign: 'center',
      fontWeight: '500',
      borderBottom: 0,
      textTransform: 'uppercase',

      [theme.breakpoints.down('xs')]: {
        fontSize: '12px',
      }
    },
    '&:nth-of-type(odd) td': {
      backgroundColor: '#2b5377',
      borderBottom: '1px solid #153552',
    },
    '&.empty td': {
      height: '30px',
    },
    '& a': {
      color: '#fff',
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'underline',
      }
    },
  },
  trEmpty: {
    '& td': {
      height: '30px',
    }
  },
}));