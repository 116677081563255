import { matchPath } from 'react-router';
import { PAGE_AIRLINE, PAGE_AIRPORT } from '../constants';

const pageTypes = [
  PAGE_AIRPORT,
  PAGE_AIRLINE,
];

const getCodeFromUrl = () => {
  for (const page of pageTypes) {
    const match = matchPath(window.location.pathname, {
      path: [`/${page}`, `/${page}/:code`],
      exact: true,
    });

    if (match) {
      return {
        type: page,
        code: match.params.code,
      };
    }
  }

  return {
    type: null,
    code: null,
  };
};

export default getCodeFromUrl;