import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import greyColor from '@material-ui/core/colors/grey';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  link: {
    display: 'block',
  },
  image: {
    display: 'block',
  },
  empty: {
    width: '100px',
    height: '24px',
    backgroundColor: '#fff',
    color: greyColor[500],
    fontSize: '16px',
    fontWeight: 'bold',
    paddingTop: '2px',
    cursor: 'default',
  }
}));

function AirlineLogo(props) {
  const { airline, callsign } = props;
  const classes = useStyles();

  if (!airline?.name) {
    return (
      <div className={classes.container}>
        <div className={classes.empty}>
          {callsign}
        </div>
      </div>
    );
  }

  return (
    <div className={classes.container}>
      <Link className={classes.link} to={`/airline/${airline.icao}`}>
        <img alt={airline.name} className={classes.image} src={airline.logo} />
      </Link>
    </div>
  );
}

export default AirlineLogo;