import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import {
  InputBase,
  Slide,
  Snackbar,
} from '@material-ui/core';
import { Send as SendIcon } from 'mdi-material-ui';
import Button from '../NavButton';
import { useStores } from 'stores';
import * as Yup from 'yup';

const SearchField = withStyles(theme => ({
  input: {
    backgroundColor: theme.palette.secondary.main,
    fontSize: '13px',
    fontWeight: 'bold',
    width: '50px',
    textAlign: 'center',
    padding: '6px 0 6px',
    textTransform: 'uppercase',
    marginRight: '10px',
    height: 'auto',
  }
}))(InputBase);

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexGrow: 0,
    alignItems: 'center',
  },
  label: {
    color: '#ffffff',
    fontSize: '12px',
    marginRight: '10px',
    whiteSpace: 'nowrap',

    [theme.breakpoints.down('xs')]: {
      display: 'none',
    }
  }
}));

function Search(props) {
  const classes = useStyles();
  const {
    appStore: {
      loading: isDataLoading,
    },
    searchStore,
  } = useStores();

  const [fieldValue, setFieldValue] = useState('');

  const [snackbarState, setSnackbarState] = useState({
    open: false,
    message: '',
  });

  useEffect(() => {
    if (fieldValue !== searchStore.value) {
      setFieldValue(searchStore.value);
    }
  }, [searchStore.value]);

  const handleSubmit = (event) => {
    event.preventDefault();

    Yup.string()
      .required('Code can not be empty')
      .length(searchStore.params.length, 'Code must be exactly ${length} characters')
      .uppercase()
      .matches(/[A-Z]+/i, 'Invalid format of the code')
      .validate(fieldValue)
      .then(searchStore.handler)
      .catch(error => setSnackbarState({
        open: true,
        message: error.message,
      }));
  };

  return (
    <>
      <div className={classes.container}>
        <div className={classes.label}>
          {searchStore.params.label && `${searchStore.params.label}:`}
        </div>
        <form onSubmit={handleSubmit}>
          <SearchField
            name='value'
            inputProps={{
              maxLength: searchStore.params.length
            }}
            value={fieldValue}
            onChange={e => setFieldValue(e.target.value)}
            disabled={isDataLoading}
            autoComplete='off'
          />
          <Button
            type='submit'
            className={classes.navButton}
            iconOnly
            disabled={isDataLoading}
          >
            <SendIcon fontSize='small' />
          </Button>
        </form>
      </div>
      <Snackbar
        open={snackbarState.open}
        autoHideDuration={3000}
        onClose={() => setSnackbarState({ ...snackbarState, open: false })}
        message={snackbarState.message}
        TransitionComponent={Slide}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      />
    </>
  );
}

export default observer(Search);