import React from 'react';
import { Button as CoreButton } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

const StyledButton = withStyles({
  root: {
    color: '#ffffff',
    backgroundColor: '#272727',
    '&:hover': {
      backgroundColor: '#383838',
    },
    '&.active': {
      backgroundColor: '#4d4d4d',
    },
    '&.icon-only': {
      paddingTop: '5px',
      paddingBottom: '5px',
      minWidth: '48px',
    },
    '&$disabled': {
      backgroundColor: 'transparent',
    },
  },
  disabled: {},
})(CoreButton);

function NavButton(props) {
  const {
    className,
    iconOnly: isIconOnly,
    ...rest
  } = props;

  const buttonClassNames = classNames(
    {
      'icon-only': isIconOnly,
    },
    className,
  );

  return (
    <StyledButton
      variant='contained'
      size='small'
      className={buttonClassNames}
      disableElevation
      {...rest}
    >
      {props.children}
    </StyledButton>
  );
}

export default NavButton;