import React from 'react';
import { useStyles } from './styles';

function Divider(props) {
  const classes = useStyles();

  return (
    <div className={classes.divider} />
  );
}

export default Divider;