import qs from 'qs';
import merge from 'lodash/merge';
import normalizeUrl from 'normalize-url';

const getApiUrl = (endpoint) => {
  const apiUrl = `${process.env.REACT_APP_API_URL}/${endpoint}`;

  return normalizeUrl(apiUrl, {
    sortQueryParameters: false,
    removeQueryParameters: ''
  });
};

export default class Api {
  static fullReloadInitiated = false;

  constructor() {
    throw new Error('Attempt to construct singleton');
  }

  static _createBodyOptions(method, data) {
    if (!data) {
      return {
        method,
        body: ''
      };
    }

    if (data instanceof FormData) {
      if (!data.has('_method')) {
        data.append('_method', method);
      }
      return {
        method: 'POST',
        body: data
      };
    }

    return {
      method,
      body: JSON.stringify(data),
      headers: {
        'content-type': 'application/json'
      }
    };
  }

  static async post(endpoint, data) {
    return this._request(endpoint, this._createBodyOptions('POST', data));
  }

  static async put(endpoint, data) {
    return this._request(endpoint, this._createBodyOptions('PUT', data));
  }

  static async patch(endpoint, data) {
    return this._request(endpoint, this._createBodyOptions('PATCH', data));
  }

  static async delete(endpoint, data) {
    return this._request(endpoint, this._createBodyOptions('DELETE', data));
  }

  static async get(endpoint, data = {}) {
    const query = qs.stringify(data, { arrayFormat: 'brackets' });
    return this._request(`${endpoint}?${query}`, { method: 'GET' });
  }

  static async _request(endpoint, additionalOptions = {}, isBlob = false) {
    const normalizedUrl = getApiUrl(endpoint);

    const response = await fetch(normalizedUrl, merge({
      headers: {
        'Accept': 'application/json',
        'Accept-Language': this.locale,
        'X-Requested-With': 'XMLHttpRequest'
      },
      credentials: 'include'
    }, additionalOptions));

    let parsedResponse;

    try {
      if (isBlob) {
        parsedResponse = await response.blob();
      } else {
        parsedResponse = await response.json();
      }
    } catch (error) {
      console.error(error);
    }

    if (response.ok) {
      return parsedResponse;
    }

    if (response.status === 401) {
      alert('401!');
      //this.hardRedirectToLoginPage();
    }

    if (response.status === 423) {
      if (!this.fullReloadInitiated) {
        window.location.reload();
      }

      this.fullReloadInitiated = true;
    }

    const throwErr = {
      ...parsedResponse,
      errors: parsedResponse.errors || {}
    };

    throw throwErr;
  }

  /*static hardRedirectToLoginPage() {
    window.location.href = getMRIDServiceUrl() + `/sign-in?${qs.stringify({
      'came-from': `${window.location.host}${window.location.pathname}`
    })}`;
  }*/
}
