import React from 'react';
import { observer } from 'mobx-react';
import { makeStyles } from '@material-ui/core/styles';
import Navigation from '../Navigation';
import Search from '../Search';
import User from './User';
import { useStores } from '../../stores';

const useStyles = makeStyles(theme => ({
  main: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    padding: '10px 20px',

    [theme.breakpoints.down('xs')]: {
      padding: '10px',
    },
  },
  search: {
    flexGrow: 0,
    display: 'flex',
  },
}));

function Header(props) {
  const {
    appStore: {
      params: appParams,
    },
  } = useStores();
  const classes = useStyles();

  return (
    <header className={classes.main}>
      <div>
        <Navigation />
      </div>
      {
        appParams.searchEnabled && (
          <div className={classes.search}>
            <Search />
          </div>
        )
      }
      {
        appParams.userEnabled && (
          <User />
        )
      }
    </header>
  );
}

export default observer(Header);