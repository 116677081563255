import React from 'react';
import { MobXProviderContext } from 'mobx-react';
import AppStore from './AppStore';
import SearchStore from './SearchStore';
import SettingsStore from './SettingsStore';
import FlightStore from './FlightStore';
import AirportStore from './AirportStore';
import AirlineStore from './AirlineStore';

export const useStores = () => {
  return React.useContext(MobXProviderContext);
};

const stores = {
  appStore: new AppStore(),
  searchStore: new SearchStore(),
  settingsStore: new SettingsStore(),
  flightStore: new FlightStore(),
  airportStore: new AirportStore(),
  airlineStore: new AirlineStore(),
};

export default stores;