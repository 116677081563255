import { flow, action, makeObservable, observable } from 'mobx';
import Api from '../Api';
import moment from 'moment';
import BaseStore from './BaseStore';

class AirportStore extends BaseStore {

  updatedAt = null;

  selected = null;
  airport = {};

  departures = [];
  arrivals = [];

  constructor() {
    super();

    makeObservable(this, {
      updatedAt: observable,
      selected: observable,
      airport: observable,
      departures: observable,
      arrivals: observable,
      selectAirport: action,
      fetchAirport: action,
      fetchFlights: action,
    });
  }

  selectAirport = (code) => {
    this.selected = code;
  }

  fetchAirport = flow(function* () {
    this.isLoading = true;

    try {
      const res = yield Api.get('info/airport/' + this.selected);

      this.airport = res?.data || {};
    } catch(err) {
      console.error(err.message);
    } finally {
      this.isLoading = false;
    }
  }).bind(this);

  fetchFlights = flow(function* () {
    this.isLoading = true;

    try {
      const res = yield Api.get('flights/airport', { code: this.selected });

      this.updatedAt = moment.unix(res?.data?.updated_at || null);
      this.departures = res?.data?.departures || [];
      this.arrivals = res?.data?.arrivals || [];
    } catch(err) {
      console.error(err.message);
    } finally {
      this.isLoading = false;
    }
  }).bind(this);

}

export default AirportStore;