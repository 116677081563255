import { action, makeObservable, observable } from 'mobx';

class SearchStore {

  params  = {
    label: 'Enter code',
    length: 4,
  };

  value = '';

  handler = () => {};

  constructor() {
    makeObservable(this, {
      params: observable,
      value: observable,
      handler: observable,
      setValue: action,
      setParams: action,
      setHandler: action,
    });
  }

  setParams = (params) => {
    this.params = Object.assign(this.params, params);

    return () => this.setParams({
      label: '',
      length: 0,
    });
  }

  setValue = (value = null) => {
    this.value = value;
    return () => this.value = '';
  };

  setHandler = (handler) => {
    this.handler = handler;
    return () => this.handler = () => {};
  }
}

export default SearchStore;
